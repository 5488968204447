import c from 'classnames';
import { PencilSimple, Trash } from 'phosphor-icons';
import { Locale, formatDistanceToNow } from 'utils';

export const ProductReviewReply = ({
  id,
  content,
  createdAt,
  locale,
  className,
  microcopy,
  actions,
}: {
  id: string;
  content: string;
  createdAt: string;
  locale: Locale;
  className?: string;
  microcopy: {
    replyFromPublisher: string;
    editReply: string;
    removeReply: string;
  };
  actions: {
    hasPermissionForActions: boolean;
    editReply: (replyId: string) => void;
    removeReply: (replyId: string) => void;
  };
}) => {
  return (
    <div
      data-test="review-reply-from-publisher"
      className={c('rounded-2xl bg-grey-100 p-4', className)}
    >
      <div className="tiny-text mb-3">{microcopy.replyFromPublisher}</div>
      <div className="tiny-text mb-5 text-grey-600">
        {formatDistanceToNow(createdAt, locale)}
      </div>
      <p
        data-test="review-reply-from-publisher-content"
        className="body-small overflow-hidden whitespace-pre-line"
      >
        {content}
      </p>
      {actions.hasPermissionForActions && (
        <div className="mt-6 flex justify-end gap-8">
          <button
            onClick={() => actions.editReply(id)}
            className="focus-outline body-small flex items-center gap-2 rounded-xl px-3 py-1 text-blue active:text-blue-hover"
          >
            <PencilSimple size={20} />
            {microcopy.editReply}
          </button>
          <button
            onClick={() => actions.removeReply(id)}
            className="focus-outline body-small flex items-center gap-2 rounded-xl px-3 py-1 text-red-500 active:text-red-600"
          >
            <Trash size={20} />
            {microcopy.removeReply}
          </button>
        </div>
      )}
    </div>
  );
};
