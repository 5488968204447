import c from 'classnames';
import xss from 'xss';
import { Link } from '../Link/Link';

type AnnouncementBarProps = {
  data: AnnouncementBarData;
};

export type AnnouncementBarData = {
  html: string;
  countdown?: string | null;
  destinationUrl?: string | null;
};

const baseClasses =
  'relative z-overlay flex min-h-10 w-full flex-col items-center justify-center bg-blue px-8 py-3 md:flex-row md:gap-x-5 md:py-2';

export const AnnouncementBar = ({ data }: AnnouncementBarProps) => {
  const announcementBar = (
    <>
      <div
        className="text-center text-white"
        dangerouslySetInnerHTML={{ __html: xss(data.html) }}
      />
      {data.countdown && (
        <div
          className="text-center font-bold text-white md:shrink-0"
          data-test="announcement-countdown"
          suppressHydrationWarning
        >
          {data.countdown}
        </div>
      )}
    </>
  );

  if (data.destinationUrl) {
    return (
      <Link
        className={c('focus:bg-blue-hover', baseClasses)}
        href={data.destinationUrl}
      >
        {announcementBar}
      </Link>
    );
  }

  return <div className={baseClasses}>{announcementBar}</div>;
};
