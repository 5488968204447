import c from 'classnames';

import { ArrowRight } from 'phosphor-icons';
import type { ButtonSize, ButtonStyle } from './types';

type ButtonOptions = {
  hasArrow: boolean;
  style: ButtonStyle;
  size: ButtonSize;
  label: string;
  className?: string;
  isDarkMode?: boolean;
};

const stylesDefault = {
  base: 'group items-center justify-center font-bold leading-5 uppercase antialiased rounded-full min-w-[6.25rem] tracking-wide disabled:pointer-events-none outline-offset-2',
  primary:
    'px-4 bg-blue transition-colors text-white duration-300 ease-out overflow-x-hidden hover:bg-blue-hover active:bg-blue-hover disabled:bg-grey-400 focus-outline',
  secondary:
    'px-4 text-blue ring-2 ring-blue bg-outline-btn-hover bg-200-100 bg-transparent bg-right bg-no-repeat [transition:color_0s,background_300ms] ease-out hover:bg-left hover:ring-blue-hover hover:text-white disabled:ring-grey-200 disabled:text-grey-400 focus-outline outline-offset-4',
  secondaryMonochrome:
    'px-4 text-black ring-2 ring-black bg-outline-btn-hover-light bg-200-100 bg-transparent bg-right bg-no-repeat [transition:color_0s,background_300ms] ease-out hover:bg-left hover:text-white disabled:ring-grey-200 disabled:text-grey-400 focus-outline outline-offset-4',
  link: 'link text-blue px-0 min-w-0',
};

const stylesDarkMode = {
  primary: stylesDefault.primary,
  secondary:
    'px-4 text-white ring-2 ring-blue bg-outline-btn-hover-dark bg-200-100 bg-transparent bg-right bg-no-repeat [transition:color_0s,background_300ms] ease-out hover:text-black hover:bg-left hover:ring-blue-hover hover:text-black disabled:ring-grey-200 disabled:text-grey-400 focus-outline outline-offset-4',
  secondaryMonochrome:
    'px-4 text-white ring-2 ring-white bg-outline-btn-hover-dark bg-200-100 bg-transparent bg-right bg-no-repeat [transition:color_0s,background_300ms] ease-out hover:bg-left hover:text-black disabled:ring-grey-200 disabled:text-grey-400 focus-outline outline-offset-4',
  link: 'link text-grey-200 px-0 min-w-0',
};

/**
 * Provides common styling and content for button and link components
 */
export const useButton = ({
  hasArrow,
  style,
  size,
  label,
  className,
  isDarkMode,
}: ButtonOptions) => {
  const displayPrimaryStyleArrow = hasArrow && style === 'primary';

  const buttonClassNames = c(
    stylesDefault.base,
    isDarkMode ? stylesDarkMode[style] : stylesDefault[style],
    {
      'py-[0.9rem]': size === 'medium',
      'text-sm': size === 'small',
      'py-2': size === 'small' && style !== 'link',
    },
    className
  );

  const buttonContainerClassNames = c(
    'flex align-middle items-center select-none transition-all duration-300',
    {
      '-mr-[1.4rem] -translate-x-[1.9rem] group-hover:translate-x-2':
        displayPrimaryStyleArrow,
    },
    {
      'px-2': (!hasArrow || style === 'secondary') && style !== 'link',
      'inline-block': style === 'link',
      'inline-flex ': style !== 'link',
    }
  );

  const buttonLabelClassNames = c({
    'flex group-hover:-ml-3 group-hover:mr-3 transition-all duration-300 ease-in-out':
      displayPrimaryStyleArrow,
  });

  const buttonContent = (
    <span className={buttonContainerClassNames}>
      {displayPrimaryStyleArrow && <ArrowRight className="mr-[1.6rem]" />}
      <span className={buttonLabelClassNames}>{label}</span>
      {displayPrimaryStyleArrow && <ArrowRight className="ml-1.5" />}
    </span>
  );

  return {
    buttonClassNames,
    buttonContent,
  };
};
